import React from 'react'
import './header.scss'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.email = "marion_ott@me.com"
  }
  render() {
    return (
      <header className="header">
        <div className="header__logo">
          <a className="header__logoItem" href="/">ṀỌ</a>
        </div>
      </header>
    );
  }
}

export default Header
